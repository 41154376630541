
import React from "react";
// Chakra imports
import {
    Box,
    Button,
    // Checkbox,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    // Icon,
    Input,
    // InputGroup,
    // InputRightElement,
    FormErrorMessage,
    Text,
    useColorModeValue,
} from "@chakra-ui/react";
// Custom components
// import { HSeparator } from "components/separator/Separator";
import DefaultAuth from "layouts/auth/Default";
// Assets
// import illustration from "assets/img/auth/auth.png";
import illustration from "assets/img/auth/authBackground.png";
// import { FcGoogle } from "react-icons/fc";
// import { MdOutlineRemoveRedEye } from "react-icons/md";
// import { RiEyeCloseLine } from "react-icons/ri";
// import { RiEyeCloseLine, MdOutlineRemoveRedEye } from 'screens/components/icons/Icons';
import { Field, Form, Formik } from "formik";
// import { FaShieldHeart } from "react-icons/fa6";
import * as Yup from "yup";
import { loadingSelector } from "redux/features/authentication/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { forgotPassword } from "redux/features/authentication/authSlice";

function ForgotPassword() {
    // Chakra color mode
    const textColor = useColorModeValue("secondaryGreen.200", "white");
    // const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
    const brandStars = useColorModeValue("secondaryGreen.200", "brand.400");
    // const [show, setShow] = React.useState(false);
    // const [showError, setShowError] = useState(false);
    const loading = useSelector(loadingSelector);
    // const auth = useSelector(authSelector);
    const dispatch = useDispatch();
    // const handleClick = () => setShow(!show);
    // useEffect(() => {
    //     if (auth?.status === 401) {
    //         setShowError(true);
    //     } else {
    //         setShowError(false);
    //     }
    // }, [auth])
    const validationSchema = Yup.object({
        email: Yup.string().required('Email is required'),
    });

    const handleForgotPassword = (values, actions) => {
        const body = {
            email: values.email,

        }
        dispatch(forgotPassword(body))
    }

    return (
        <DefaultAuth illustrationBackground={illustration} image={illustration}>
            <Flex
                maxW={{ base: "100%", md: "max-content" }}
                w="100%"
                mx={{ base: "auto", lg: "0px" }}
                me="auto"
                h="100%"
                alignItems="start"
                justifyContent="center"
                mb={{ base: "30px", md: "60px" }}
                px={{ base: "25px", md: "0px" }}
                mt={{ base: "40px", md: "50px" }}
                flexDirection="column"
            >
                <Box me="auto">
                    <Heading color={textColor} fontSize="36px" mb="50px">
                        Forgot Password
                    </Heading>
                </Box>
                <Flex
                    zIndex="2"
                    direction="column"
                    w={{ base: "100%", md: "420px" }}
                    maxW="100%"
                    background="transparent"
                    borderRadius="15px"
                    mx={{ base: "auto", lg: "unset" }}
                    me="auto"
                    mb={{ base: "20px", md: "auto" }}
                >
                    <Formik
                        initialValues={{
                            email: "",
                        }}
                        onSubmit={(values, actions) => {
                            handleForgotPassword(values, actions)
                        }}
                        validationSchema={validationSchema}
                    >
                        {(props) => (
                            <Form>
                                <Field name="email">
                                    {({ field, form }) => (
                                        <FormControl
                                            isInvalid={form.errors.email && form.touched.email}
                                        >
                                            <FormLabel
                                                display="flex"
                                                htmlFor="email"
                                                ms="4px"
                                                fontSize="sm"
                                                fontWeight="500"
                                                color={textColor}
                                                mb="8px"
                                            >
                                                Email<Text color={brandStars}>*</Text>
                                            </FormLabel>
                                            <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                                            <Input
                                                isRequired={true}
                                                {...field}
                                                id="email"
                                                name="email"
                                                borderColor={form.errors.email && form.touched.email && "red.500"}
                                                variant="auth"
                                                fontSize="sm"
                                                ms={{ base: "0px", md: "0px" }}
                                                // type="email"
                                                placeholder="mail@mail.com"
                                                mb="24px"
                                                fontWeight="500"
                                                size="lg"
                                            />
                                        </FormControl>
                                    )}
                                </Field>


                                <Button
                                    fontSize="sm"
                                    color="white"
                                    type="submit"
                                    backgroundColor="secondaryGreen.200"
                                    fontWeight="500"
                                    isLoading={loading === 'loading'}
                                    w="100%"
                                    _hover={{ bg: 'secondaryGreen.100' }}
                                    h="50"
                                    mb="24px"
                                >
                                    Reset Password
                                </Button>
                            </Form>
                        )}
                    </Formik>
                </Flex>
            </Flex>
        </DefaultAuth>
    );
}

export default ForgotPassword;
